import React, { useState } from 'react'
import CacheBuster from 'react-cache-buster'
import { LIB_VERSION } from './version'
import { BrowserRouter as Router } from 'react-router-dom'

import HttpsRedirect from 'react-https-redirect'
import 'bootstrap/dist/css/bootstrap.min.css'

import Header from './components/Header'
import Main from './components/Main'
import Footer from './components/Footer'
import Loading from './components/Loading'

import Navbar from './components/Navbar'


import './App.css'

export default function App() {
  const [bannerType, setBannerType] = useState(['all'])
  const [edit, setEdit] = useState(false)
  const [currentUser, setCurrentUser] = useState(null)
  // console.log(LIB_VERSION)

  const editMode = (e) => {
    setEdit(!edit)
  }

  // const updateMascot = (newMascot, newType = false) => {
  //   if (newMascot !== mascot) {
  //     setMascot(newMascot)
  //     if (newType) {
  //       setBannerType(['all', newType])
  //     } else {
  //       setBannerType(['all'])
  //     }
  //   }
  // }

  return (
    <CacheBuster
    currentVersion={LIB_VERSION}
    isEnabled={true} //If false, the library is disabled.
    isVerboseMode={false} //If true, the library writes verbose logs to console.
    loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
    metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
  >
    <div className="App grid-container rbabkg">
      <HttpsRedirect>
        <Router basename="/">
          <div className="griditem1">
            <Header
              bannerType={bannerType}
              currentUser={{ currentUser, setCurrentUser }}
            />
             
          </div>
          <div className="griditem2 content">
            <Main />
          </div>
          <div className="griditem3">
            <Footer />
          </div>
        </Router>
      </HttpsRedirect>
    </div>
  </CacheBuster>
  )
}
