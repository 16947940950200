import React from 'react'

import Comingsoon from '../components/Comingsoon/'

export default function Home() {
  
  return (
    <div className="container-100" >
      <Comingsoon />
    </div>
  )
}
