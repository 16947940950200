import React from 'react'
import './header.css'
// import { Link } from "react-router-dom";
// import mascot from '../../imgs/brand/swagocto.png'
// import Banner from '../../views/cms/Banner.cms'
import AllNav from '../Navbar'

// import InstallPWA from '../InstallPWA' <InstallPWA />

export default function Header({ editMode, currentUser, bannerType  }) {
  // console.log(bannerType)

  return (
    <div className="header-wrapper">
       <div className="header shadow">
          <AllNav className="allnav" currentUser={currentUser} />
          </div>
    </div>
  )
}
