import React from 'react'
import { Link } from 'react-router-dom'
import './style.css'

// import Txtflow from '../Txtflow/'
import Logo from '../../imgs/rba/newlogoxl.png'


export default function Comingsoon() {
  

  return (
    <div className="row" >
      <div className="pt-5 mt-5 col-12 center">
        <img src={Logo} alt="ReelBig Logo" className="col-lg-6 col-md-8 col-sm-10 col-xs-11 ml-auto mr-auto mt-5 pt-5" />
        {/* <Txtflow /> */}
      
        
      </div>
      <h5 className='col-12 center text-white upper mt-5'>fish on!</h5>

      <Link className='row btn btn-lg col-2 mt-5 center btn-warning text-white bg-second rounded' title='LOGIN' to="/login">LOGIN</Link>
    </div>
  )
}
