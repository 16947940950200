import React, { useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import AdminBoard from './Boards/Admin'

// import POflowDashboard from '../views/POflow'
// import PDFoutput from '../views/POflow/PDFoutput'
// import Install from './InstallPWA'
import Login from './Login'
import Home from '../views/Home'
import Register from './Register'
import Profile from './Profile'
// import MessageBoard from '../views/UserMessages'

// import '../App.css'

export default function Main() {
  return (
    <div id="maincontent">
      <Switch>

        <Route exact path={['/']} component={Home} />
        
        <Route exact path={['/login']} component={Login} />
        <Route exact path={['/register']} component={Register} />

        {/* gives a parameter, this can be used to select a board */}
        {/* <Route
          exact
          path={['/dashboard']}
          component={AdminBoard}
          component={() => <AdminBoard />}
        /> */}

        <Route
          exact
          path={'/profile'}
          // component={Profile}
          component={() => <Profile />}
        />

        <Route
          exact
          path={'/dashboard'}
          component={Profile}
          // component={() => <Profile />}
        />

        {/* <Route
          exact
          path={'/poflow/output'}
          // component={POflowDashboard}
          component={() => <PDFoutput />}
        /> */}

   
        {/* <Route exact path="/admin" component={AdminBoard} /> */}
        {/* <Route exact path="/profile" component={Profile} /> */}
        

     
        
      </Switch>
    </div>
  )
}
